import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { navigate } from "gatsby"
import { useFirebase } from "react-redux-firebase"
import axios from "axios"

import LogoSpinner from "general/components/LogoSpinner"

import { authenticated, loginWithToken } from "auth/redux"
import { getShopDomain } from "services/shopify"

function InstallPage(props) {
  const { loginWithToken } = props
  const firebase = useFirebase()
  const shop = getShopDomain()

  const [loading, setLoading] = useState(true)

  const queryParams = window.location.search
  const urlParams = new URLSearchParams(queryParams)
  const uid = urlParams.get("uid")

  useEffect(() => {
    const reqeustAuth = shop => {
      axios.post(`/api/auth`, { shop }).then(response => {
        if (response.status === 200) {
          window.top.location.href = response.data.body
        }
      })
    }

    const verifyToken = async shop => {
      const token = await firebase.auth().currentUser.getIdToken(true)

      const response = await axios({
        method: "post",
        url: "/api/verifyToken",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })

      if (response.data.status === 200) {
        return setLoading(false)
      }

      reqeustAuth(shop)
    }

    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        const uid = user.uid

        if (uid !== shop) {
          return firebase.logout()
        }

        verifyToken(shop)
      } else {
        if (uid) {
          loginWithToken({ uid })
        } else {
          if (shop) {
            reqeustAuth(shop)
          }
          // TODO: SET INPUT STATE
        }
      }
    })
  }, [])

  if (loading) {
    return (
      <div className={`h-screen flex flex-1 items-center justify-center`}>
        <LogoSpinner />
      </div>
    )
  }

  if (!loading) {
    navigate("/app/design")
    return null
  }

  return "NULL"
}

const mapDispatchToProps = {
  authenticated,
  loginWithToken,
}

export default connect(undefined, mapDispatchToProps)(InstallPage)
